// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

import { SliderAssociation } from "../../components/SharedComponents/SliderWithInformation/SliderInfo.interfaces";

// convention of Redux's `@@INIT` action.
export enum fluxplaneActionTypes {
  SET_CONFIG = "@@fluxPlaneConfig/SET_CONFIG",
  SET_RANK_VALUES = "@@fluxPlaneConfig/SET_RANK_VALUES",
  SET_INDEXED_RANK_VALUES = "@@fluxPlaneConfig/SET_INDEXED_RANK_VALUES",
  SET_MAX_RANK_VALUE = "@@fluxPlaneConfig/SET_MAX_RANK_VALUE",
  SET_SLIDER_VALUES = "@@fluxPlaneConfig/SET_SLIDER_VALUES",
  SET_SETTINGS_PROFILE = "@@fluxPlaneConfig/SET_SETTINGS_PROFILE",
  INIT_SLIDER_ASSOCIATIONS = "@@fluxPlaneConfig/INIT_SLIDER_ASSOCIATIONS",
  SET_SLIDER_VALUES_ASSOCIATIONS = "@@fluxPlaneConfig/SET_SLIDER_VALUES_ASSOCIATIONS",
  REMOVE_SLIDER_VALUES_ASSOCIATIONS = "@@fluxPlaneConfig/REMOVE_SLIDER_VALUES_ASSOCIATIONS",
  CLEAR_SLIDER_VALUES_ASSOCIATIONS = "@@fluxPlaneConfig/CLEAR_SLIDER_VALUES_ASSOCIATIONS",
  SET_FLUXPLANE_CONFIG = "@@fluxPlaneConfig/SET_FLUXPLANE_CONFIG",
  RESET = "@@fluxPlaneConfig/RESET",
}

export type FluxplaneAction = () => void;

export interface SliderValuesData {
  sliderKey: number;
  values: number[];
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface fluxPlaneState {
  [key: string]: any;
  sliderValuesAssociations: { [key: string]: { interval: string, sliderAssociations: SliderAssociation[] } };
  rank_values: number[][];
  slider_values: number[][];
  indexed_rank_values: SliderValuesData[];
  max_rank_value: number;
}

export default fluxplaneActionTypes;
