// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the
// convention of Redux's `@@INIT` action.
export enum windroseActionTypes {
  SET_HEADING = "@@windrose/SET_HEADING"
}

interface SetHeading {
  type: typeof windroseActionTypes.SET_HEADING;
  heading: number;
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface WindroseState {
  heading: number;
  loading: boolean;
  error: string | null;
}

export type WindroseActionTypes = SetHeading;
