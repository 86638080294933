// Copyright 2021 SeekOps Inc.
// third-party
import { Reducer } from "redux";

// first-party
import {
  IPremiumTableIndividualState,
  IPremiumTableState,
  premiumTableActionTypes,
} from "./premiumTable.types";

// Type-safe initialState
// make inital state for premium table
export const initialPremiumTableState: IPremiumTableState = {
  tables: {},
};

const updateTableById = <T>(
  state: IPremiumTableState,
  tableId: string,
  key: keyof IPremiumTableIndividualState,
  value: T
): IPremiumTableState => {
  const existingTable = state.tables[tableId];

  if (existingTable) {
    return {
      ...state,
      tables: {
        ...state.tables,
        [tableId]: {
          ...existingTable,
          [key]: value,
        },
      },
    };
  } else {
    const newTable: IPremiumTableIndividualState = {
      tableId,
      rows: [],
      columns: [],
      tableActions: [],
      searchModel: undefined,
      sortModel: [],
      paginationModel: undefined,
      filterModel: undefined,
      mandatoryFilterFields: [],
      dataEndpoint: "",
      isLoading: false,
      actionId: null,
      currentAction: null,
      [key]: value,
    };

    return {
      ...state,
      tables: {
        ...state.tables,
        [tableId]: newTable,
      },
    };
  }
};


// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.
const premiumTableReducer: Reducer<IPremiumTableState> = (
  state = initialPremiumTableState,
  action: any
) => {
  switch (action.type) {
    case premiumTableActionTypes.SET_ROWS: {
      return updateTableById(state, action.tableId, "rows", action.rows);
    }
    case premiumTableActionTypes.SET_COLUMNS: {
      return updateTableById(state, action.tableId, "columns", action.columns);
    }
    case premiumTableActionTypes.SET_TABLE_ACTIONS: {
      return updateTableById(state, action.tableId, "tableActions", action.tableActions);
    }
    case premiumTableActionTypes.SET_SEARCH_MODEL: {
      return updateTableById(state, action.tableId, "searchModel", action.searchModel);
    }
    case premiumTableActionTypes.SET_SORT_MODEL: {
      return updateTableById(state, action.tableId, "sortModel", action.sortModel);
    }
    case premiumTableActionTypes.SET_PAGINATION_MODEL: {
      return updateTableById(state, action.tableId, "paginationModel", action.paginationModel);
    }
    case premiumTableActionTypes.SET_FILTER_MODEL: {
      return updateTableById(state, action.tableId, "filterModel", action.filterModel);
    }
    case premiumTableActionTypes.SET_MANDATORY_FILTER_FIELDS: {
      return updateTableById(state, action.tableId, "mandatoryFilterFields", action.mandatoryFilterFields);
    }
    case premiumTableActionTypes.SET_DATA_ENDPOINT: {
      return updateTableById(state, action.tableId, "dataEndpoint", action.newDataEndpoint);
    }
    case premiumTableActionTypes.APPLY_IS_LOADING: {
      return updateTableById(state, action.tableId, "isLoading", action.isLoading);
    }
    case premiumTableActionTypes.SET_ACTION_ID: {
      return updateTableById(state, action.tableId, "actionId", action.actionId);
    }
    case premiumTableActionTypes.SET_CURRENT_ACTION: {
      return updateTableById(state, action.tableId, "currentAction", action.currentAction);
    }
    case premiumTableActionTypes.INITIALIZE_TABLE: {
      if (!state.tables[action.tableId]) {
        const newTable: IPremiumTableIndividualState = {
          tableId: action.tableId,
          rows: [],
          columns: [],
          tableActions: [],
          searchModel: undefined,
          sortModel: [],
          paginationModel: undefined,
          filterModel: undefined,
          mandatoryFilterFields: [],
          dataEndpoint: "",
          isLoading: false,
          actionId: null,
          currentAction: null,
        };
    
        return {
          ...state,
          tables: {
            ...state.tables,
            [action.tableId]: newTable,
          },
        };
      }
    
      return state;
    }
    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default premiumTableReducer;
