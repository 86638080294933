// Copyright 2021 SeekOps Inc.
/**
 * Do Not Modify. Only Add
 * Follow O for SOLID
 * Open for addition, Closed for modification
 *
 * @export
 * @enum {number}
 */
export enum StatusType {
  Completed = "Completed",
  Cancelled = "Cancelled",
  Error = "Error",
  Deleted = "Deleted",
  InProgress = "InProgress",
}

/**
 * Do Not Modify. Only Add
 * Follow O for SOLID
 * Open for addition, Closed for modification
 *
 * @export
 * @enum {number}
 */
export enum TaskType {
  File = "File",
  Photo = "Photo",
  Report = "Report",
}
