// Copyright 2021 SeekOps Inc.
// Example for using discriminated union types.
export type languageSelectorStoreLocales = "en-US" | "es-MX";

// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention
// of Redux's `@@INIT` action.
export enum languageSelectorStoreActionTypes {
  SET_LOCALE = "@@languageSelectorStore/SET_LOCALE",
  SET_DIR = "@@languageSelectorStore/SET_DIR",
  GET_LOCALE = "@@languageSelectorStore/GET_LOCALE"
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface languageSelectorStoreState {
  locale: string;
  dir: string;
}
