// Copyright 2021 SeekOps Inc.
//third-party
import { Reducer } from "redux";

//first-party
import { ErrorDialogState, ErrorDialogActionTypes } from "./errorDialog.types";

// Type-safe initialState!
export const initialState: ErrorDialogState = {
  isDialogOpen: false,
};

// Thanks to Redux 4's much simpler typings, we can take away a lot of typings
// on the reducer side, everything will remain type-safe.

const errorDialogReducer: Reducer<ErrorDialogState> = (
  state = initialState,
  action
) => {
  switch (action.type) {
    case ErrorDialogActionTypes.OPEN_DIALOG: {
      let newState: ErrorDialogState = {
        ...state,
        isDialogOpen: true,
      };
      return newState;
    }
    case ErrorDialogActionTypes.CLOSE_DIALOG: {
      let newState: ErrorDialogState = {
        ...state,
        isDialogOpen: false,
      };
      return newState;
    }

    default:
      return state;
  }
};

// Instead of using default export, we use named exports. That way we can group
// these exports inside the `index.js` folder.
export default errorDialogReducer;
