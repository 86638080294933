// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the convention

import { ICompany } from "../../components/CompanySwitcher/CompanySwitcher.interfaces";

// of Redux's `@@INIT` action.
export enum authenticationActionTypes {
  SET_TOKEN = "@@authentication/SET_TOKEN",
  SET_REFRESH_TOKEN = "@@authentication/SET_REFRESH_TOKEN",
  SIGN_OUT = "@@authentication/SIGN_OUT",
  AUTH_START = "@@authentication/AUTH_START",
  AUTH_SUCCESS = "@@authentication/AUTH_SUCCESS",
  AUTH_FAIL = "@@authentication/AUTH_FAIL",
  AUTH_LOGOUT = "@@authentication/AUTH_LOGOUT",
  INITIALIZE = "@@authentication/INITIALIZE",
  INITIALIZE_SUCCESSFUL = "@@authentication/INITIALIZE_SUCCESSFUL",
  INITIALIZE_FAILED = "@@authentication/INITIALIZE_FAILED",
  TOKEN_VERIFY_CURRENT = "@@authentication/TOKEN_VERIFY_CURRENT",
  TOKEN_VERIFY_CURRENT_SUCCESS = "@@authentication/TOKEN_VERIFY_CURRENT_SUCCESS",
  TOKEN_VERIFY_CURRENT_CANCEL = "@@authentication/TOKEN_VERIFY_CURRENT_CANCEL",
  TOKEN_REFRESH = "@@authentication/TOKEN_REFRESH",
  TOKEN_REFRESH_SUCCESS = "@@authentication/TOKEN_REFRESH_SUCCESS",
  SET_ACCEPTANCE = "@@authentication/SET_ACCEPTANCE",
  SET_SESSION_EXPIRED = "@@authentication/SET_SESSION_EXPIRED",
  SET_MSAL_ACCESS_TOKEN = "@@authentication/SET_MSAL_ACCESS_TOKEN",
  SET_COMPANY = "@@authentication/SET_COMPANY",
}

interface tokenVerifyCurrent {
  type: typeof authenticationActionTypes.TOKEN_VERIFY_CURRENT;
}

interface tokenVerifyCurrentSuccess {
  type: typeof authenticationActionTypes.TOKEN_VERIFY_CURRENT_SUCCESS;
  token: string;
  refreshToken: string;
}

interface SetTokenAction {
  type: typeof authenticationActionTypes.SET_TOKEN;
  token: string;
}

interface SignOutAction {
  type: typeof authenticationActionTypes.SIGN_OUT;
}

interface Initialize {
  type: authenticationActionTypes.INITIALIZE;
  loading: boolean;
}

interface InitializeFailed {
  type: typeof authenticationActionTypes.INITIALIZE_FAILED;
  token: string;
  refreshToken: string;
  userID: string;
  companyID: number;
}

interface InitializeSuccessful {
  type: typeof authenticationActionTypes.INITIALIZE_SUCCESSFUL;
  token: string;
  refreshToken: string;
  refreshExpiry: string;
  userID: string;
  userIDNumber: number;
  userNameFirst: string;
  userNameLast: string;
  companyID: number;
  companyName: string;
  companies: ICompany[];
  eulaAccepted: boolean;
}

interface setCompany {
  type: typeof authenticationActionTypes.SET_COMPANY;
  companyID: number;
  companyName: string;
}

// const authSuccess = (
//   state: authenticationState,
//   action: AuthenticationActionTypes
// ) => {
//   return updateObject(state, {
//     token: action.token,
//     userId: action.userId,
//     error: null,
//     loading: false
//   });
// };

// interface AuthStartAction {
//   type: typeof authenticationActionTypes.AUTH_START;
// }

interface SetRefreshTokenAction {
  type: typeof authenticationActionTypes.SET_REFRESH_TOKEN;
  refreshToken: string;
}

interface setMSALAccessToken {
  type: typeof authenticationActionTypes.SET_MSAL_ACCESS_TOKEN;
  MSALAccessToken: string;
}

interface tokenRefresh {
  type: typeof authenticationActionTypes.TOKEN_REFRESH;
}

interface tokenRefreshSuccess {
  type: typeof authenticationActionTypes.TOKEN_REFRESH_SUCCESS;
  token: string;
  refreshToken: string;
}

interface setIsAccepted {
  type: typeof authenticationActionTypes.SET_ACCEPTANCE;
  isAccepted: boolean;
}

interface setSessionExpired {
  type: typeof authenticationActionTypes.SET_SESSION_EXPIRED;
  signedOutAutomatically: boolean;
}

// Declare state types with `readonly` modifier to get compile time immutability.
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface AuthenticationState {
  token: string | null;
  refreshToken: string | null;
  refreshExpiry: string | null;
  isAuthenticated: boolean;
  userIDNumber: number | null;
  userID: string | null;
  userNameFirst: string | null;
  userNameLast: string | null;
  companyID: number | null;
  companyName: string | null;
  loading: boolean;
  eulaAccepted: boolean;
  error: string | null;
  signedOutAutomatically: boolean;
  companies: ICompany[];
  MSALAccessToken: string | null;
}

export type AuthenticationActionTypes =
  | SetTokenAction
  | SetRefreshTokenAction
  | setMSALAccessToken
  | setCompany
  | SignOutAction
  | Initialize
  | InitializeFailed
  | InitializeSuccessful
  | tokenVerifyCurrent
  | tokenVerifyCurrentSuccess
  | tokenRefresh
  | tokenRefreshSuccess
  | setIsAccepted
  | setSessionExpired;
