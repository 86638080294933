// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

// convention of Redux's `@@INIT` action.
export enum ErrorDialogActionTypes {
  OPEN_DIALOG = "@@errorDialog/OPEN_DIALOG",
  CLOSE_DIALOG = "@@errorDialog/CLOSE_DIALOG",
}

export interface ErrorDialogState {
  isDialogOpen: boolean;
}

export default ErrorDialogActionTypes;
