// Copyright 2021 SeekOps Inc.
import { defineAbility } from "@casl/ability";
import store from "../store";

/**
 * Contains Action Subject pair which
 * @react/casl <Can> Stateless Component uses to perform an ability check
 *
 * Usage
 * <Can do={action} on={subject}>
 *
 * @export
 * @interface AbilityToCheck
 */
export interface AbilityToCheck {
  action: string;
  subject: string;
}

export default defineAbility((can: any) => {
  let abilities = store.getState().authorization.abilities;

  for (const ability in abilities) {
    if (abilities.hasOwnProperty(ability)) {
      if (ability !== "null" && abilities[ability] !== null) {
        const element: string[] = abilities[ability];
        can(element, ability);
      }
    }
  }
});
