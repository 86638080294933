// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

// convention of Redux's `@@INIT` action.
export enum QualityAssuranceActionTypes {
  SET_SURVEY_ID_FOR_REVIEW = "@@qualityAssurance/SET_SURVEY_ID_FOR_REVIEW",
  SET_NOTES = "@@qualityAssurance/SET_NOTES",
  SET_IS_PROCESSING_FINISHED = "@@qualityAssurance/SET_IS_PROCESSING_FINISHED",
  SET_NORMALIZE_ALTITUDE = "@@qualityAssurance/SET_NORMALIZE_ALTITUDE",
  SET_GPS_ALTITUDE_OFFSET = "@@qualityAssurance/SET_GPS_ALTITUDE_OFFSET",
  SET_GPS_ALTITUDE_OFFSET_PRESET = "@@qualityAssurance/SET_GPS_ALTITUDE_OFFSET_PRESET",
  SET_WIND_HEADING_OFFSET = "@@qualityAssurance/SET_WIND_HEADING_OFFSET",
  SET_WIND_TYPE = "@@qualityAssurance/SET_WIND_TYPE",
  SET_MINIMUM_GPS_ALTITUDE = "@@qualityAssurance/SET_MINIMUM_GPS_ALTITUDE",
  RESET = "@@qualityAssurance/RESET",
}

export interface QualityAssuranceState {
  surveyIDForReview: number;
  notes: string;
  isProcessingFinished: boolean;
  normalizeAltitude: boolean;
  gpsAltitudeOffset: number | null;
  gpsAltitudeOffsetPreset: number | null;
  windHeadingOffset: number | null;
  minimumGPSAltitude: number | null;
  windType: "raw" | "adjusted" | "offset";
}

export default QualityAssuranceActionTypes;
