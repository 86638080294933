// Copyright 2021 SeekOps Inc.
import { Color } from "@mui/material";
import { Palettes } from "./theme.interfaces";
import { ColorPaletteName } from "./theme.enums";

/**
 * the collection of MUI Color objects that define a pallet for a spefic color
 */
const palettes: Palettes = {
  primaryred: {
    50: "#f5e4e4",
    100: "#e7bbbc",
    200: "#d78e90",
    300: "#c66064",
    400: "#ba3e42",
    500: "#FF3436",
    600: "#a7191d",
    700: "#9d1418",
    800: "#941114",
    900: "#84090b",
    A100: "#ffb3b4",
    A200: "#ff8082",
    A400: "#ff4d4f",
    A700: "#ff3436",
  },
  oxbloodred: {
    50: "#efe1e1",
    100: "#d6b3b3",
    200: "#bb8081",
    300: "#a04d4e",
    400: "#8b2728",
    500: "#770102",
    600: "#6f0102",
    700: "#640101",
    800: "#5a0101",
    900: "#470001",
    A100: "#ff7b7b",
    A200: "#ff4848",
    A400: "#ff1515",
    A700: "#270001",
  },
  galaxygray: {
    50: "#e7e7e7",
    100: "#c2c2c2",
    200: "#9a9a9a",
    300: "#727272",
    400: "#535353",
    500: "#353535",
    600: "#303030",
    700: "#282828",
    800: "#222222",
    900: "#161616",
    A100: "#ef6f6f",
    A200: "#ea4141",
    A400: "#f80000",
    A700: "#df0000",
  },
  lightgray: {
    50: "#f9fafa",
    100: "#f1f2f2",
    200: "#e8e9ea",
    300: "#dfe0e1",
    400: "#d8dada",
    500: "#d1d3d4",
    600: "#cccecf",
    700: "#c6c8c9",
    800: "#c0c2c3",
    900: "#b5b7b9",
    A100: "#ffffff",
    A200: "#ffffff",
    A400: "#ffffff",
    A700: "#f4fbff",
  },
  charcoalgray: {
    50: "#eaeaea",
    100: "#cccbcb",
    200: "#aaa9a9",
    300: "#878787",
    400: "#6e6d6d",
    500: "#545353",
    600: "#4d4c4c",
    700: "#434242",
    800: "#3a3939",
    900: "#292929",
    A100: "#f28888",
    A200: "#ed5a5a",
    A400: "#ff1414",
    A700: "#fa0000",
  },
  jewelgreen: {
    50: "#9CD2B1",
    100: "#87C8A0",
    200: "#5FB581",
    300: "#4FAE74",
    400: "#32965E",
    500: "#157e48",
    600: "#10733E",
    700: "#157e48",
    800: "#0B6734",
    900: "#065C2A",
    A100: "#B4DDC4",
    A200: "#248A53",
    A400: "#137943",
    A700: "#333333",
  },
};

/**
 * Returns the Color palette object corresponding to the given color
 * palette name
 *
 * @param colorName
 * @return the Color palette object corresponding to the given color
 * palette name
 */
const getColorPalette = (colorPaletteName: ColorPaletteName): Color => {
  return palettes[colorPaletteName];
};

export default getColorPalette;
