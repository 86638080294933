// Copyright 2021 SeekOps Inc.
import "./wdyr";

import { FC } from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import { LicenseInfo } from "@mui/x-license";

import "./config/theme/fonts.css";
import App from "./App";
import reportWebVitals from "./reportWebVitals";

// MSAL imports
import {
  PublicClientApplication,
  EventType,
  EventMessage,
  AuthenticationResult,
} from "@azure/msal-browser";
import { msalConfig } from "./authConfig";
import { MsalProvider } from "@azure/msal-react";

console.info(
  `


  ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄            ▄▄▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄▄▄  ▄         ▄  ▄▄▄▄▄▄▄▄▄▄▄ 
 ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░▌          ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░▌       ▐░▌▐░░░░░░░░░░░▌
 ▐░█▀▀▀▀▀▀▀▀▀ ▐░█▀▀▀▀▀▀▀▀▀ ▐░▌          ▐░█▀▀▀▀▀▀▀▀▀  ▀▀▀▀█░█▀▀▀▀ ▐░▌       ▐░▌▐░█▀▀▀▀▀▀▀▀▀ 
 ▐░▌          ▐░▌          ▐░▌          ▐░▌               ▐░▌     ▐░▌       ▐░▌▐░▌          
 ▐░▌          ▐░█▄▄▄▄▄▄▄▄▄ ▐░▌          ▐░█▄▄▄▄▄▄▄▄▄      ▐░▌     ▐░▌       ▐░▌▐░█▄▄▄▄▄▄▄▄▄ 
 ▐░▌          ▐░░░░░░░░░░░▌▐░▌          ▐░░░░░░░░░░░▌     ▐░▌     ▐░▌       ▐░▌▐░░░░░░░░░░░▌
 ▐░▌          ▐░█▀▀▀▀▀▀▀▀▀ ▐░▌           ▀▀▀▀▀▀▀▀▀█░▌     ▐░▌     ▐░▌       ▐░▌ ▀▀▀▀▀▀▀▀▀█░▌
 ▐░▌          ▐░▌          ▐░▌                    ▐░▌     ▐░▌     ▐░▌       ▐░▌          ▐░▌
 ▐░█▄▄▄▄▄▄▄▄▄ ▐░█▄▄▄▄▄▄▄▄▄ ▐░█▄▄▄▄▄▄▄▄▄  ▄▄▄▄▄▄▄▄▄█░▌ ▄▄▄▄█░█▄▄▄▄ ▐░█▄▄▄▄▄▄▄█░▌ ▄▄▄▄▄▄▄▄▄█░▌
 ▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌▐░░░░░░░░░░░▌
  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀  ▀▀▀▀▀▀▀▀▀▀▀ 
                                                                                            

  
                                                                      
  _                 _____                 _       _____                
 (_)               (_____)   ____   ____ (_) _   (_____)          ____ 
 (_)_    _   _    (_)___    (____) (____)(_)(_) (_)   (_)  ____  (____)
 (___)_ (_) (_)     (___)_ (_)_(_)(_)_(_)(___)  (_)   (_) (____) (_)__ 
 (_)_(_)(_)_(_)     ____(_)(__)__ (__)__ (_)(_) (_)___(_) (_)_(_) _(__)
 (____)  (____)    (_____)  (____) (____)(_) (_) (_____)  (____) (____)
          __(_)                                           (_)          
         (___)                                            (_)          
 
  `
);

export const pca = await PublicClientApplication.createPublicClientApplication(
  msalConfig
);

// Account selection logic is app dependent. Adjust as needed for different use cases.
const accounts = pca.getAllAccounts();
if (accounts.length > 0) {
  pca.setActiveAccount(accounts[0]);
}

pca.addEventCallback((event: EventMessage) => {
  if (event.eventType === EventType.LOGIN_SUCCESS && event.payload) {
    const payload = event.payload as AuthenticationResult;
    const account = payload.account;
    pca.setActiveAccount(account);
  }
});

// MUI X LICENSE
const MUI_X_PREMIUM = `${process.env.REACT_APP_MUI_X_PREMIUM}`;
LicenseInfo.setLicenseKey(MUI_X_PREMIUM);

// Component
const AppProvider: FC<any> = ({ children, ...rest }) => (
  <MsalProvider instance={pca}>
    <BrowserRouter basename="/">
      <App pca={pca} />
    </BrowserRouter>
  </MsalProvider>
);

const root = createRoot(document.getElementById("root") as HTMLElement);

if (root) {
  root.render(<AppProvider />);
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
