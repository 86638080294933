// Copyright 2021 SeekOps Inc.
// Use const enums for better autocompletion of action type names. These will
// be compiled away leaving only the final value in your compiled code.
//
// Define whichever naming conventions you'd like for your action types, but
// personally, I use the `@@context/ACTION_TYPE` convention, to follow the

import { StatusType, TaskType } from "./TaskTypes.enum";

// convention of Redux's `@@INIT` action.
export enum tasksActionTypes {
  ADD_TASK = "@@tasks/ADD_TASK",
  REMOVE_TASK = "@@tasks/REMOVE_TASK",
  REMOVE_ALL_TASKS = "@@tasks/REMOVE_ALL_TASKS",
  SET_FILE_QUANTITY = "@@tasks/SET_FILE_QUANTITY",
  SET_COMPLETED = "@@tasks/SET_COMPLETED",
  SET_IN_PROGRESS = "@@tasks/SET_IN_PROGRESS",
  SET_ERROR = "@@tasks/SET_ERROR",
  RESET = "@@tasks/RESET",
}

// Declare state types with `readonly` modifier to get compile time immutability
// https://github.com/piotrwitek/react-redux-typescript-guide#state-with-type-level-immutability
export interface tasksState {
  [TaskType.File]: { [key: string]: reduxTask };
  [TaskType.Photo]: { [key: string]: reduxTask };
  [TaskType.Report]: { [key: string]: reduxTask };
  fileQuantity: number;
  //id's
  inProgress: string[];
  completed: string[];
  error: string[];
}

export interface reduxTask {
  //NOTE: No need to keep payload related information,
  //since if still in progress, payload needs to be corroborated by calling api
  id: string;
  status: StatusType;
  //Placeholder object designed to keep any relevant object that might aid when reading task
  placeholder?: any;
}
export default tasksActionTypes;
